import { config } from "./client-config";

type AccessTokenResponse = {
  access_token: string;
};

export const getLoginUrl = (state?: LoginState, signUp?: boolean): string => {
  const paramData: Record<string, string> = {
    client_id: config.authAppId,
    response_type: "code",
    scope: "email openid profile",
    redirect_uri: config.loginRedirectUrl, // Should this add some token to make it unique for the user?
    ...(state
      ? {
          state: btoa(JSON.stringify(state)),
        }
      : {}),
  };

  const queryParam = Object.keys(paramData)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(paramData[key])}`
    )
    .join("&");

  const path = signUp ? "signup" : "login";
  return `${config.authApiUrl}/${path}?${queryParam}`;
};

export const getLogoutUrl = (): string => {
  const paramData: Record<string, string> = {
    client_id: config.authAppId,
    // redirect_uri is used to redirect back to a login flow and needs the response_type parameter as well
    logout_uri: config.logoutRedirectUrl,
  };

  const queryParam = Object.keys(paramData)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(paramData[key])}`
    )
    .join("&");

  return `${config.authApiUrl}/logout?${queryParam}`;
};

export const login = async ({
  authorizationCode,
  redirectUri,
}: {
  authorizationCode?: string;
  redirectUri: string;
}): Promise<AccessTokenResponse | null> => {
  try {
    const response = await fetch(`${config.apiUrl}/login`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authorizationCode,
        redirectUri, // Must be the same that the code was requested on!
      }),
    });

    if (response.status !== 200) {
      return null;
    }

    const data = await response.json();
    const tokenData = data as AccessTokenResponse;

    if (tokenData?.access_token) {
      localStorage.setItem("token", tokenData.access_token);
    }

    return tokenData;
  } catch (error: any) {
    console.error(error.message, error.stack);
    return null;
  }
};

export const refresh = async ({
  redirectUri,
}: {
  redirectUri: string;
}): Promise<AccessTokenResponse | null> => {
  try {
    const response = await fetch(`${config.apiUrl}/refresh`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        redirectUri, // Must be the same that the code was requested on!
      }),
    });

    console.log("*** RESPONSE", response.status, response.statusText);

    if (response.status !== 200) {
      return null;
    }

    const data = (await response.json()) as AccessTokenResponse;

    if (data.access_token) {
      localStorage.setItem("token", data.access_token);
    }

    return data;
  } catch (error: any) {
    console.error(error.message, error.stack);
    return null;
  }
};

export const logout = async (): Promise<string | null> => {
  try {
    const response = await fetch(`${config.apiUrl}/logout`, {
      credentials: "include",
      method: "POST",
    });

    localStorage.removeItem("token");

    const data = await response.json();
    return data;
  } catch (error: any) {
    console.error(error.message, error.stack);
    return null;
  }
};
