import { useState, useEffect } from "react";
import { apiClient } from "../utils/api-client";

export const useApiInfo = (): any => {
  const [apiInfo, setApiInfo] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiClient.getApiInfo();
        setApiInfo(data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return apiInfo;
};
