import React, { useEffect, useContext, useState } from "react";
import { apiClient } from "../utils/api-client";

export const GoogleLoginPage = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const doLogin = async () => {
      const data = await apiClient.auth.googleLoginCallback();
      setData(data);
    };

    doLogin();
  }, []);

  return (
    <div style={{ width: "100%", margin: "20px" }}>
      {data ? <div>Data: {JSON.stringify(data)}</div> : "Logging in..."}
    </div>
  );
};
