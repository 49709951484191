import React from "react";
import { getQueryParam } from "../utils/query-params";
import { usePlans } from "../hooks/use-plans";
import { Link } from "react-router-dom";
import { getLoginUrl } from "../utils/auth-api-client";

export const LoginToPurchasePage = () => {
  const planId = getQueryParam("plan") as SubscriberPlan;
  const plans = usePlans();

  const selectedPlan = plans.find((plan) => plan.id === planId);

  return (
    <div style={{ padding: "40px" }}>
      <h2>Purchase Plan: {selectedPlan?.name}</h2>
      <div>
        <h3>New Customer?</h3>
        <a href={getLoginUrl()}>Register</a>
      </div>
      <div>
        <h3>Existing Customer?</h3>
        <a href={getLoginUrl()}>Sign In</a>
      </div>
    </div>
  );
};
