import React from "react";

export type ApplicationState = {
  loading: boolean;
  identity: Identity | null;
  welcomeText: string;
  modalData?: {
    title: string;
    message: string;
  };
  setLoading: (loading: boolean) => any;
  setIdentity: (identity: Identity | null) => any;
  updateWelcomeText: (text: string) => any;
  showModal: (title: string, message: string) => any;
  hideModal: () => any;
};

export const initialState: Omit<
  ApplicationState,
  | "authenticatedFetch"
  | "setLoading"
  | "setIdentity"
  | "updateWelcomeText"
  | "showModal"
  | "hideModal"
> = {
  loading: true,
  identity: null,
  welcomeText: "Hello, Traveller!",
  modalData: undefined,
};

export const ApplicationContext = React.createContext<ApplicationState>(
  initialState as ApplicationState
);
