import React from "react";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

export const Spinner = () => {
  return (
    <div>
      <div className="App">
        <header className="App-header">
          <BootstrapSpinner color="white" />
        </header>
      </div>
    </div>
  );
};
