import React, { useContext } from "react";
import { ApplicationContext } from "./context/application-context";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const AppModal = () => {
  const { modalData, hideModal } = useContext(ApplicationContext);
  const handleClose = () => hideModal();

  return (
    <Modal show={!!modalData} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalData?.message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
