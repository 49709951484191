import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ApplicationContext } from "../context/application-context";
import { useAccountDetails } from "../hooks/use-account-details";

export const AccountPage = () => {
  const { identity } = useContext(ApplicationContext);
  const accountDetails = useAccountDetails();

  // Redirect to login?
  if (!identity) {
    return (
      <div className="App">
        <header className="App-header">Forbidden</header>
      </div>
    );
  }

  return (
    <div>
      <h1>Account</h1>
      <h2>Subscription: {JSON.stringify(identity, null, 2)}</h2>
      <div>Details: {JSON.stringify(accountDetails)};</div>
      {accountDetails?.billingDashboardUrl && (
        <Button onClick={() => window.open(accountDetails.billingDashboardUrl)}>
          Manage Plan
        </Button>
      )}
    </div>
  );
};
