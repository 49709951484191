import React, { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { usePlans } from "../../hooks/use-plans";

interface Props {
  planId: string;
}

export const PlanDetails = ({ planId }: Props) => {
  const plans = usePlans();
  const selectedPlan = plans.find((p) => p.id === planId);

  if (!selectedPlan) {
    return <Alert variant="danger">No valid plan selected</Alert>;
  }

  return (
    <div>
      <h3>Plan Details</h3>
      <div>name: {selectedPlan.name}</div>
      <div>price: {selectedPlan.pricingText}</div>
      <div>features: {JSON.stringify(selectedPlan.features)}</div>
    </div>
  );
};
