import {
  WidgetDefinition,
  CustomerWidget,
  CustomerWidgetDraft,
} from "../types";
import { dummyData } from "./dummy-data";
import { config } from "../utils/client-config";
import { PaymentIntent, PaymentIntentResult } from "@stripe/stripe-js";

type AccessTokenResponse = {
  accessToken: string;
  error?: string;
};

type SubscriptionPreparationResponse = {
  plan: Plan;
  subscriptionId: string;
  clientSecret: string;
};

type SubscriptionCompletedResponse = {
  subscriptionType: SubscriberPlan;
  status: "incomplete" | "active";
  invoiceId: string;
};

type SignUpResponse = {
  userId: string;
  code: number;
};

type GoogleLoginRedirectResponse = {
  loginUrl: string;
};

type GoogleLoginCallbackResponse = {
  provider: string;
  displayName: string;
  name: {
    giveNName: string;
    familyName: string;
  };
  email: string;
  email_verified: string;
  verified: string;
};

type IdpLoginUrlResponse = {
  loginUrl: string;
};

const auth = {
  signUp: async ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }): Promise<SignUpResponse> => {
    const response = await fetch(`${config.apiUrl}/auth/signup`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password }),
    });

    return (await response.json()) as SignUpResponse;
  },
  confirmSignUp: async ({
    email,
    confirmationCode,
  }: {
    email: string;
    confirmationCode: string;
  }): Promise<boolean> => {
    const response = await fetch(`${config.apiUrl}/auth/confirm`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, confirmationCode }),
    });

    return response.status === 200;
  },
  login: async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<AccessTokenResponse> => {
    const response = await fetch(`${config.apiUrl}/auth/login`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const data = (await response.json()) as AccessTokenResponse;

    // Store access token
    if (data.accessToken) {
      localStorage.setItem("token", data.accessToken);
    }

    return data;
  },
  refresh: async ({
    redirectUri,
  }: {
    redirectUri: string;
  }): Promise<AccessTokenResponse | null> => {
    try {
      const response = await fetch(`${config.apiUrl}/auth/refresh`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          redirectUri, // Must be the same that the code was requested on!
        }),
      });

      console.log("*** RESPONSE", response.status, response.statusText);

      if (response.status !== 200) {
        return null;
      }

      const data = (await response.json()) as AccessTokenResponse;

      if (data.accessToken) {
        localStorage.setItem("token", data.accessToken);
      }

      return data;
    } catch (error: any) {
      console.error(error.message, error.stack);
      return null;
    }
  },
  logout: async (): Promise<boolean> => {
    const response = await fetch(`${config.apiUrl}/auth/logout`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // Need to send access token for logout action
      body: JSON.stringify({
        accessToken: localStorage.getItem("token"),
      }),
    });

    localStorage.removeItem("token");
    return response.status === 200 ? true : false;
  },
  getGoogleLoginRedirect: async (): Promise<GoogleLoginRedirectResponse> => {
    const response = await fetch(`${config.apiUrl}/auth/google/initiate`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return (await response.json()) as GoogleLoginRedirectResponse;
  },
  getIdpLogin: async (provider: string): Promise<IdpLoginUrlResponse> => {
    const response = await fetch(`${config.apiUrl}/auth/idp`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return (await response.json()) as IdpLoginUrlResponse;
  },
  googleLoginCallback: async (): Promise<GoogleLoginCallbackResponse> => {
    const queryString = window.location.search;
    const response = await fetch(
      `${config.apiUrl}/auth/google/callback${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return (await response.json()) as GoogleLoginCallbackResponse;
  },
};

const root = {
  getApiInfo: async () => {
    const response = await fetch(config.apiUrl, { credentials: "include" });
    return await response.json();
  },
};

const identity = {
  getIdentity: async (): Promise<Identity | null> => {
    const response = await authenticatedFetch(
      `${config.apiUrl}/account/identity`
    );

    if (response?.status !== 200) {
      return null;
    }

    return await response?.json();
  },
};

const account = {
  getAccountDetails: async (): Promise<AccountDetails | null> => {
    const response = await authenticatedFetch(`${config.apiUrl}/account`);
    return (await response?.json()) as AccountDetails;
  },
};

const plans = {
  getPlans: async (): Promise<Plan[]> => {
    const response = await fetch(`${config.apiUrl}/products/plans`, {
      credentials: "include",
    });
    const data = await response.json();
    return data.plans;
  },
};

const widgets = {
  getWidgetDefinitions: async (): Promise<WidgetDefinition[]> => {
    return dummyData.widgetDefinitions;
  },
  getCustomerWidgets: async (): Promise<CustomerWidget[]> => {
    return Object.keys(dummyData.customerWidgetsById).map(
      (id) => dummyData.customerWidgetsById[id]
    );
  },
  getCustomerWidget: async (id: string): Promise<CustomerWidget> => {
    return dummyData.customerWidgetsById[id];
  },
  postCustomerWidget: async (
    widget: CustomerWidgetDraft
  ): Promise<CustomerWidget> => {
    const id = (Math.random() * 1000).toString(); //crypto.randomUUID(); TODO
    const newWidget: CustomerWidget = {
      ...widget,
      id,
    };
    dummyData.customerWidgetsById[id] = newWidget;
    return newWidget;
  },
  putCustomerWidget: async (
    widget: CustomerWidget
  ): Promise<CustomerWidget> => {
    dummyData.customerWidgetsById[widget.id] = widget;
    return widget;
  },
};

const stripeSinglePurchases = {
  createPaymentIntent: async (): Promise<PaymentIntent> => {
    const response = await fetch(`${config.apiUrl}/paymentIntent`, {
      method: "POST",
    });
    return await response.json();
  },
  completePayment: async (paymentIntent: PaymentIntent) => {
    const response = await fetch(`${config.apiUrl}/completePayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentIntent),
    });
    return await response.json();
  },
};

const stripeCheckout = {
  createCheckoutSession: async (planId: string): Promise<string> => {
    const response = await fetch(`${config.apiUrl}/checkoutSession`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ planId }),
    });
    const data = await response.json();
    return data.checkoutUrl;
  },
  checkoutSessionCompleted: async (sessionId: string): Promise<string> => {
    const response = await authenticatedFetch(
      `${config.apiUrl}/checkoutSessionCompleted`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionId }),
      }
    );
    return await response?.json();
  },
};

const payPalSinglePurchases = {
  createOrder: async () => {
    const response = await fetch(`${config.apiUrl}/orders`, {
      method: "POST",
      // use the "body" param to optionally pass additional order information
      // like product ids or amount
    });

    const order = await response.json();

    console.log("*** CREATED ORDER", order);

    return order;
  },
  captureOrder: async (orderId: string) => {
    const response = await fetch(`${config.apiUrl}/orders/${orderId}/capture`, {
      method: "post",
    });

    const orderData = await response.json();
    return orderData;
  },
};

const stripeSubscriptions = {
  prepareSubscription: async (
    planId: string
  ): Promise<SubscriptionPreparationResponse> => {
    const response = await authenticatedFetch(
      `${config.apiUrl}/subscriptions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          planId,
        }),
      }
    );
    return (await response?.json()) as SubscriptionPreparationResponse;
  },
  subscriptionPaymentCompleted: async ({
    paymentIntentId,
    subscriptionId,
  }: {
    paymentIntentId: string;
    subscriptionId: string;
  }): Promise<SubscriptionCompletedResponse> => {
    const response = await authenticatedFetch(
      `${config.apiUrl}/subscriptions/complete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentIntentId,
          subscriptionId,
        }),
      }
    );
    return (await response?.json()) as SubscriptionCompletedResponse;
  },
};

export const apiClient = {
  auth,
  ...root,
  ...identity,
  ...account,
  ...plans,
  ...stripeSinglePurchases,
  ...stripeCheckout,
  ...payPalSinglePurchases,
  ...stripeSubscriptions,
  ...widgets,
};

const authenticatedFetch = async (
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response | null> => {
  const executeFetch = async (
    input: RequestInfo | URL,
    init?: RequestInit
  ): Promise<Response | null> => {
    const accessToken = localStorage.getItem("token");

    // We're not logged in and have no token, do nothing
    if (!accessToken) return null;

    console.log("*** ACCESS TOKEN", accessToken);

    const authHeader = `Bearer ${accessToken}`;

    init = init
      ? {
          ...init,
          headers: init.headers
            ? {
                ...init.headers,
                Authorization: authHeader,
              }
            : {
                Authorization: authHeader,
              },
        }
      : {
          headers: {
            Authorization: authHeader,
          },
        };

    return fetch(input as RequestInfo, init);
  };

  try {
    const response = await executeFetch(input, init);

    if (response?.status === 200) {
      return response;
    }

    // Need to refresh the token
    if (response?.status === 401) {
      // CLONE required to allow consumers to 're-read' the response if it's not a refresh
      const body = await response.clone().json();

      console.log("*** ERROR BODY", body);

      if (body?.error === "TokenExpiredError") {
        // Refresh the token
        const refreshResponse = await apiClient.auth.refresh({
          redirectUri: config.loginRedirectUrl,
        });

        // Got a new token?
        if (refreshResponse?.accessToken) {
          console.log("*** SUCCESSFUL REFRESH", refreshResponse);

          // Re-execute
          return executeFetch(input, init);
        }

        // If the refresh failed force a login - but clear the token so we only try this once!
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    }

    return response;
  } catch (error) {
    console.log("*** FETCH ERROR (not due to http status)", error);
    return null;
  }
};
