import React from "react";

export const WidgetsPage = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Widgets</h1>
      </header>
    </div>
  );
};
