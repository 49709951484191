import { useState, useEffect } from "react";
import {
  ApplicationContext,
  ApplicationState,
  initialState,
} from "./application-context";
import { apiClient } from "../utils/api-client";

const appStart = new Date();

export const ApplicationContextProvider = ({ children }: { children: any }) => {
  useEffect(() => {
    const fetchData = async () => {
      // Only try to refresh if we have a token in local storage left
      if (!localStorage.getItem("token")) {
        setLoading(false);
        return;
      }

      try {
        // Will do refresh of token if required
        const identity = await apiClient.getIdentity();

        if (identity) {
          setIdentity(identity);
        }

        // Show loading screen for at least 1 second
        const remainder = 1000 - (new Date().getTime() - appStart.getTime());
        setTimeout(() => setLoading(false), Math.max(remainder, 0));
      } catch (error) {
        console.error("Failed to login", error);
      }
    };

    // Don't refresh the token on the login/logout pages
    if (!["/login", "/logout"].includes(window.location.pathname)) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  const setLoading = (loading: boolean) => {
    setState((prevState: ApplicationState) => ({
      ...prevState,
      loading: loading,
    }));
  };

  const setIdentity = (identity: Identity | null) => {
    setState((prevState: ApplicationState) => ({
      ...prevState,
      identity: identity,
    }));
  };

  const updateWelcomeText = (text: string) => {
    setState((prevState: ApplicationState) => ({
      ...prevState,
      welcomeText: text,
    }));
  };

  const showModal = (title: string, message: string) => {
    setState((prevState: ApplicationState) => ({
      ...prevState,
      modalData: {
        title,
        message,
      },
    }));
  };

  const hideModal = () => {
    setState((prevState: ApplicationState) => ({
      ...prevState,
      modalData: undefined,
    }));
  };

  const [state, setState] = useState({
    ...initialState,
    setLoading,
    setIdentity,
    updateWelcomeText,
    showModal,
    hideModal,
  });

  return (
    <ApplicationContext.Provider value={state}>
      {children}
    </ApplicationContext.Provider>
  );
};
