import React from "react";
import "./App.css";
import { ApplicationContextProvider } from "./context/application-context-provider";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AppModal } from "./AppModal";
import { AppContainer } from "./AppContainer";
import { HomePage } from "./pages/HomePage";
import { WidgetsPage } from "./pages/WidgetsPage";
import { ContactPage } from "./pages/ContactPage";
import { LoginPage } from "./pages/LoginPage";
import { LogoutPage } from "./pages/LogoutPage";
import { AccountPage } from "./pages/AccountPage";
import { CheckoutPage } from "./pages/CheckoutPage";
import { PricingTablePage } from "./pages/PricingTablePage";
import { PurchasePlanPage } from "./pages/PurchasePlanPage";
import { PurchaseSuccessPage } from "./pages/PurchaseSuccessPage";
import { LoginToPurchasePage } from "./pages/LoginToPurchasePage";
import { GoogleLoginPage } from "./pages/GoogleLoginPage";
import { Navbar } from "./components/Navbar";

const App = () => {
  return (
    <ApplicationContextProvider>
      <AppContainer>
        {/* 
      // @ts-ignore */}
        <BrowserRouter>
          <AppModal />
          <Navbar />
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/login/google">
              <GoogleLoginPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/logout">
              <LogoutPage />
            </Route>
            <Route path="/signup">
              <LoginToPurchasePage />
            </Route>
            <Route path="/widget">
              <WidgetsPage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/account">
              <AccountPage />
            </Route>
            <Route path="/pricing">
              <PricingTablePage />
            </Route>
            <Route path="/purchase">
              <PurchasePlanPage />
            </Route>
            <Route path="/success">
              <PurchaseSuccessPage />
            </Route>
            <Route path="/checkout">
              <CheckoutPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </BrowserRouter>
      </AppContainer>
    </ApplicationContextProvider>
  );
};

export default App;
