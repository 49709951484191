import {
  WidgetDefinition,
  CustomerWidget,
  CustomerWidgetDraft,
} from "../types";

export const dummyData: {
  widgetDefinitions: WidgetDefinition[];
  customerWidgetsById: Record<string, CustomerWidget>;
} = {
  widgetDefinitions: [
    {
      id: (Math.random() * 1000).toString(), //crypto.randomUUID(),
      name: "Review",
      supportedSettings: {
        source: {
          description: "Source of the review",
          type: "Text",
        },
      },
    },
    {
      id: (Math.random() * 1000).toString(),
      name: "Review Form",
      supportedSettings: {
        style: {
          description: "Style of the review form",
          type: "Option",
          allowedValues: ["Compact", "Fullscreen"],
        },
      },
    },
  ],
  customerWidgetsById: {},
};
