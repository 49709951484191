import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getQueryParam } from "../utils/query-params";
import { apiClient } from "../utils/api-client";

export const PurchaseSuccessPage = () => {
  const [error, setError] = useState("");

  useEffect(() => {
    const checkoutSessionCompleted = async () => {
      const sessionId = getQueryParam("session_id");

      try {
        console.log("*** SESSION ID", sessionId);

        const response = await apiClient.checkoutSessionCompleted(sessionId);

        console.log("*** RESPONSE", response);
      } catch (err: any) {
        setError(`Failed to complete purchase: ${err.message}`);
      }
    };

    checkoutSessionCompleted();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {error ? (
          <div>ERROR: {error}</div>
        ) : (
          <div>
            Processing...
            <Spinner />
          </div>
        )}
      </header>
    </div>
  );
};
