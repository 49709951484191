import React, { useEffect, useContext } from "react";
import { ApplicationContext } from "../context/application-context";
import { Spinner } from "../components/Spinner";
import { useHistory } from "react-router-dom";
import { apiClient } from "../utils/api-client";

export const LogoutPage = () => {
  const { setIdentity } = useContext(ApplicationContext);
  const history = useHistory();

  useEffect(() => {
    const doLogout = async () => {
      const response = await apiClient.auth.logout();
      console.log("*** LOGOUT response", response);

      setTimeout(() => {
        setIdentity(null);
        history.push("/");
      }, 500);
    };

    doLogout();
  }, []);

  return <Spinner />;
};
