import React, { useEffect, useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";

export const ForgotPasswordForm = () => {
  return (
    <div>
      <h2>Forgot Password?</h2>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
        </Form.Group>
        <Button variant="primary" type="submit">
          Request Password Reset
        </Button>
      </Form>
    </div>
  );
};
