import React, { useContext, useEffect, useState } from "react";
import { PaypalCheckout } from "../components/PaypalCheckout";
import { StripeCheckout } from "../components/StripeCheckout";
import { StripePricingTable } from "../components/StripePricingTable";
import { ApplicationContext } from "../context/application-context";
import {
  Container,
  CardGroup,
  Card,
  Button,
  ListGroup,
  Alert,
} from "react-bootstrap";
import { getLoginUrl } from "../utils/auth-api-client";
import { usePlans } from "../hooks/use-plans";
import { Form } from "react-bootstrap";
import { getQueryParam } from "../utils/query-params";
import { Spinner } from "../components/Spinner";
import { apiClient } from "../utils/api-client";
import { LoginForm } from "../components/forms/LoginForm";
import { PlanDetails } from "../components/checkout/PlanDetails";
import { SubscriptionPaymentForm } from "../components/checkout/SubscriptionPaymentForm";
import { CheckoutSuccessMessage } from "../components/checkout/CheckoutSuccessMessage";

type CheckoutState =
  | "Loading"
  | "Login"
  | "SubscriptionPayment"
  | "ProductPayment"
  | "Success";

export const CheckoutPage = () => {
  const [error, setError] = useState("");
  const { identity } = useContext(ApplicationContext);
  const [state, setState] = useState<CheckoutState>("Loading");
  const planId = getQueryParam("plan");
  const productId = getQueryParam("product"); // maybe
  const [invoiceId, setInvoiceId] = useState("");

  const gotoPaymentState = () => {
    if (planId) {
      setState("SubscriptionPayment");
    } else {
      setState("ProductPayment");
    }
  };

  useEffect(() => {
    if (!planId && !productId) {
      setError("Invalid checkout session;");
    }

    if (identity) {
      gotoPaymentState();
    } else {
      setState("Login");
    }
  }, [planId, productId]);

  if (error) {
    return (
      <div>
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  }

  const control: any = {
    Loading: <Spinner />,
    Login: (
      <LoginForm
        successCallback={(identity: Identity) => {
          gotoPaymentState();
        }}
      />
    ),
    SubscriptionPayment: (
      <SubscriptionPaymentForm
        planId={planId}
        onSuccess={(invoiceId: string) => {
          setInvoiceId(invoiceId);
          setState("Success");
        }}
      />
    ),
    ProductPayment: <div>PRODUCT PAYMENT FORM</div>,
    Success: <CheckoutSuccessMessage invoiceId={invoiceId} />,
  }[state];

  // const [subscriptionIntentId, setSubscriptionIntentId] = useState("");

  // useEffect(() => {
  //   const prepareSubscription = async () => {
  //     const subscriptionPrep = await apiClient.prepareSubscription();
  //     setSubscriptionIntentId(subscriptionPrep.paymentIntentId);
  //   };

  //   prepareSubscription();
  // }, [identity]);

  console.log("*** IDENTITY", identity);

  return (
    <div style={{ padding: "40px" }}>
      <h2>Checkout Page</h2>
      <PlanDetails planId={planId} />
      {control}
    </div>
  );
};
