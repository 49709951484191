import React from "react";
import { getQueryParam } from "../utils/query-params";
import { usePlans } from "../hooks/use-plans";

export const PurchasePlanPage = () => {
  const planId = getQueryParam("plan") as SubscriberPlan;
  const plans = usePlans();

  const selectedPlan = plans.find((plan) => plan.id === planId);

  return (
    <div style={{ padding: "40px" }}>
      <h2>Purchase Plan: {selectedPlan?.name}</h2>
    </div>
  );
};
