import React, { useContext } from "react";
import { ApplicationContext } from "../context/application-context";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import BootstrapNavbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const { identity } = useContext(ApplicationContext);

  return (
    <BootstrapNavbar bg="light" expand="lg">
      <Container>
        <BootstrapNavbar.Brand as={Link} to="/">
          Smart Website Widgets
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Widgets" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/widgets/reviews">
                Reviews & Review Form
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/widgets/evergreen-timer">
                Evergreen Timer
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/widgets/sale-banner">
                Sale Banner
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/feedback">
                Feedback Form
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/pricing">
              Pricing
            </Nav.Link>
            <Nav.Link as={Link} to="/contact">
              Contact Us
            </Nav.Link>
            {identity && (
              <Nav.Link as={Link} to="/account">
                Account
              </Nav.Link>
            )}
            {identity ? (
              <Nav.Link as={Link} to="/logout">
                Logout
              </Nav.Link>
            ) : (
              <Nav.Link as={Link} to="/login">
                Login
              </Nav.Link>
            )}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};
