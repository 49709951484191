type ClientConfig = {
  siteUrl: string;
  authAppId: string;
  apiUrl: string;
  authApiUrl: string;
  loginRedirectUrl: string;
  logoutRedirectUrl: string;
  stripePublicKey: string;
  paypalClientId: string;
  buildNumber: string;
  buildEnv: Environment;
};

const prodConfig: ClientConfig = {
  siteUrl: "https://www.smartwebwidgets.com",
  authAppId: "2rfjn63kfkllkimdga2hkvscln",
  apiUrl: "https://api.smartwebwidgets.com",
  authApiUrl: "https://auth.smartwebwidgets.com",
  loginRedirectUrl: "https://www.smartwebwidgets.com/login",
  logoutRedirectUrl: "https://www.smartwebwidgets.com/logout",
  stripePublicKey: "pk_live_obgMlvjUUM9G1Ep9pjJWBtG800by7VJRXa",
  paypalClientId:
    "Ab6cyJxd0kN3KJ0iHEj_aP5KP2E6_Ssw7Iis0b-MGw4PiGwvDY_6aHAJRzRwWsWsKk4--w6W83YIXkSM",
  buildNumber:
    (typeof process !== "undefined" && process.env.REACT_APP_BUILD_NUMBER) ||
    "undefined",
  buildEnv:
    (typeof process !== "undefined" &&
      (process.env.REACT_APP_BUILD_ENV as Environment)) ||
    "local",
};

const devConfig: ClientConfig = {
  ...prodConfig,
  siteUrl: "https://dev.smartwebwidgets.com",
  authAppId: "25es7b1adpd0v5p5p81a254jta",
  apiUrl: "https://api-dev.smartwebwidgets.com",
  authApiUrl: "https://auth-dev.smartwebwidgets.com",
  loginRedirectUrl: "https://dev.smartwebwidgets.com/login",
  logoutRedirectUrl: "https://dev.smartwebwidgets.com/logout",
  stripePublicKey: "pk_test_9KDTb9ns6IBdwSWDIS7G933Z00BHrLLhRE",
  paypalClientId:
    "Ab6cyJxd0kN3KJ0iHEj_aP5KP2E6_Ssw7Iis0b-MGw4PiGwvDY_6aHAJRzRwWsWsKk4--w6W83YIXkSM",
};

const localConfig: ClientConfig = {
  ...devConfig,
  siteUrl: "http://localhost:3333",
  apiUrl: "http://localhost:3000",
  loginRedirectUrl: "http://localhost:3333/login",
  logoutRedirectUrl: "http://localhost:3333/logout",
  paypalClientId:
    "Ab6cyJxd0kN3KJ0iHEj_aP5KP2E6_Ssw7Iis0b-MGw4PiGwvDY_6aHAJRzRwWsWsKk4--w6W83YIXkSM",
  buildNumber: devConfig.buildNumber || "local-build",
  buildEnv: devConfig.buildEnv || "local-env",
};

export const config: ClientConfig =
  typeof process === "undefined" || !process.env.REACT_APP_BUILD_ENV
    ? localConfig
    : process.env.REACT_APP_BUILD_ENV === "production"
    ? prodConfig
    : devConfig;
