import { useState, useEffect } from "react";
import { apiClient } from "../utils/api-client";

export const usePlans = (): Plan[] => {
  const [plans, setPlans] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const plans = await apiClient.getPlans();
        setPlans(plans);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return plans;
};
