import React, { useEffect, useContext, useState } from "react";
import { ApplicationContext } from "../context/application-context";
import { login } from "../utils/auth-api-client";
import { config } from "../utils/client-config";
import { useHistory } from "react-router-dom";
import { Spinner } from "../components/Spinner";
import { getQueryParam } from "../utils/query-params";
import { apiClient } from "../utils/api-client";
import { Form, Button } from "react-bootstrap";
import { LoginForm } from "../components/forms/LoginForm";
import { SignUpForm } from "../components/forms/SignupForm";
import { ForgotPasswordForm } from "../components/forms/ForgotPasswordForm";

type Mode = "login" | "signup" | "forgot-password";

export const LoginPage = () => {
  const { identity } = useContext(ApplicationContext);

  const [mode, setMode] = useState<Mode>("login");

  const children = {
    login: [
      <LoginForm redirectUrl="/account" />,
      <a href="#" onClick={() => setMode("signup")}>
        New User?
      </a>,
      <a href="#" onClick={() => setMode("forgot-password")}>
        Forgot password?
      </a>,
    ],
    signup: [
      <SignUpForm />,
      <a href="#" onClick={() => setMode("login")}>
        Existing User?
      </a>,
    ],
    "forgot-password": [
      <ForgotPasswordForm />,
      <a href="#" onClick={() => setMode("login")}>
        Back to login
      </a>,
      <a href="#" onClick={() => setMode("signup")}>
        New User?
      </a>,
    ],
  }[mode] || <Spinner />;

  return (
    <div style={{ width: "100%", margin: "20px" }}>
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </div>
  );
};
