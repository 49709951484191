import React, { useEffect, useContext, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { ApplicationContext } from "../../context/application-context";
import { apiClient } from "../../utils/api-client";
import { useHistory } from "react-router-dom";

export const SignUpForm = () => {
  const { setIdentity } = useContext(ApplicationContext);
  const history = useHistory();
  const [error, setError] = useState("");
  const [signedUpEmail, setSignedUpEmail] = useState("");
  const [signedUpPassword, setSignedUpPassword] = useState("");

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const loginResponse = await apiClient.auth.login({ email, password });

    if (!loginResponse.accessToken) {
      setError("Failed to login");
      return;
    }

    const identity = await apiClient.getIdentity();

    console.log("*** IDENTITY RESPONSE", identity);

    if (identity) {
      setIdentity(identity);
      history.push("/account");
    }
  };

  const onSignUp = async (event: any) => {
    event.preventDefault();
    setError("");

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries()) as any;

    const signUpResponse = await apiClient.auth.signUp({
      name: formDataObj.name,
      email: formDataObj.email,
      password: formDataObj.password,
    });

    // Go to confirmation step
    if (signUpResponse.userId) {
      setSignedUpEmail(formDataObj.email);
      setSignedUpPassword(formDataObj.password);
    } else if (signUpResponse.code === 325) {
      setError("Invalid username");
    }
  };

  const onConfirmSignUp = async (event: any) => {
    event.preventDefault();
    setError("");

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries()) as any;

    const confirmSignUpResponse = await apiClient.auth.confirmSignUp({
      email: signedUpEmail,
      confirmationCode: formDataObj.confirmationCode,
    });

    console.log("*** CONFIRM RESPONSE", confirmSignUpResponse);

    // Login
    if (confirmSignUpResponse) {
      login({ email: signedUpEmail, password: signedUpPassword });
    } else {
      setError("Invalid confirmation code");
    }
  };

  if (!signedUpEmail) {
    return (
      <div>
        <h2>Sign Up</h2>
        <Form onSubmit={onSignUp}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" type="text" placeholder="Enter name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control name="email" type="email" placeholder="Enter email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Sign Up
          </Button>
        </Form>
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
    );
  }

  return (
    <div>
      <Form onSubmit={onConfirmSignUp}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter email"
            value={signedUpEmail}
            disabled={true}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            name="confirmationCode"
            type="text"
            placeholder="Confirmation Code"
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Confirm email
        </Button>
      </Form>
      {error && <Alert variant="danger">{error}</Alert>}
    </div>
  );
};
