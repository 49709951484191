import React, { useContext } from "react";
import { ApplicationContext } from "../context/application-context";
import { Container, CardGroup, Card, Button, ListGroup } from "react-bootstrap";
import { usePlans } from "../hooks/use-plans";
import { useHistory } from "react-router-dom";

const getCard = (plan: Plan, active: boolean, history: any) => {
  return (
    <Card style={{ textAlign: "center" }} key={plan.id}>
      <Card.Header>
        <h4>{plan.name}</h4>
      </Card.Header>
      <Card.Body>
        <Card.Title>{plan.pricingText}</Card.Title>
        <ListGroup variant="flush">
          {(plan.features || []).map((feature) => (
            <ListGroup.Item
              style={{ border: "0px" }}
              key={`${plan.id}-${feature}`}
            >
              {feature}
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Button
          disabled={active}
          onClick={() => history.push(`/checkout?plan=${plan.id}`)}
        >
          {active
            ? "Current plan"
            : plan.type === "free"
            ? "Sign up for free"
            : "Sign up"}
        </Button>
      </Card.Body>
    </Card>
  );
};

export const PricingTablePage = () => {
  const history = useHistory();
  const plans = usePlans();
  const { identity } = useContext(ApplicationContext);

  console.log("*** PLANS", plans);

  const planCards = plans.map((plan) =>
    getCard(plan, identity?.activeSubscription?.type === plan.type, history)
  );

  return (
    <div style={{ padding: "40px" }}>
      <Container>
        <CardGroup>{planCards}</CardGroup>
      </Container>
    </div>
  );
};
