import { useState, useEffect } from "react";
import { apiClient } from "../utils/api-client";

export const useAccountDetails = (): AccountDetails | null => {
  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiClient.getAccountDetails();
        setAccountDetails(data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  return accountDetails;
};
