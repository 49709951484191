let lastSearch = "";
let hashSet: Record<string, string> = {};

export const getQueryParam = (param: string) => {
  if (lastSearch !== window.location.search) {
    lastSearch = window.location.search;
    hashSet = {};

    const queryString = window.location.search.substr(1);
    for (const pair of queryString?.split("&")) {
      const [key, value] = pair.split("=");
      hashSet[key] = value;
    }
  }

  return hashSet[param];
};
