// https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements&client=react#customize-layout
// https://stripe.com/docs/billing/subscriptions/webhooks#active-subscriptions
// https://stripe.com/docs/stripe-js/react
import React, { useEffect, useState } from "react";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { config } from "../../utils/client-config";
import { Alert, Spinner } from "react-bootstrap";
import { apiClient } from "../../utils/api-client";

// https://stripe.com/docs/testing

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripePublicKey);

interface Props {
  planId: string;
  onSuccess: (invoiceId: string) => void;
}

type State = "Initialising" | "UserInput" | "Processing" | "Success";

export const SubscriptionPaymentForm = ({ planId, onSuccess }: Props) => {
  const [state, setState] = useState<State>("Initialising");
  const [error, setError] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [confirmedPlan, setConfirmedPlan] = useState<Plan | null>(null);

  useEffect(() => {
    const prepareSubscription = async () => {
      const subscription = await apiClient.prepareSubscription(planId);
      console.log("*** PREPARED SUBSCRIPTION", subscription);

      const { plan, clientSecret, subscriptionId } = subscription;

      if (plan && clientSecret) {
        setClientSecret(clientSecret);
        setSubscriptionId(subscriptionId);
        setConfirmedPlan(plan);
        setState("UserInput");
      } else {
        setError("Failed to initiliase payment");
      }
    };
    prepareSubscription();
  }, [planId]);

  if (state === "Initialising") {
    return <Spinner />;
  }

  if (state === "Success") {
    return <div>SUCCESS</div>;
  }

  if (state === "Processing") {
    return (
      <div>
        <h2>Payment Details</h2>
        Processing... <Spinner />
      </div>
    );
  }

  const options = {
    // passing the client secret obtained in step 3
    clientSecret,
    // Fully customizable with appearance API.
    appearance: {},
  };

  return (
    <div>
      <h2>Payment Details</h2>
      <div>
        You are purchasing the {confirmedPlan?.name} plan for{" "}
        {confirmedPlan?.pricingText}
      </div>
      {(config.buildEnv === "local" || config.buildEnv === "development") && (
        <div style={{ margin: "40px 0" }}>
          Test card: 4242424242424242
          <br />
          Decline: 4000000000000002
          <br />
        </div>
      )}
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          subscriptionId={subscriptionId}
          completedCallback={(invoiceId?: string, error?: string) => {
            if (error) {
              setState("UserInput");
              setError(error);
            } else {
              setError("");
              setState("Success");
              onSuccess(invoiceId || "UNKNOWN");
            }
          }}
        />
      </Elements>
      {error && <Alert variant="danger">{error}</Alert>}
    </div>
  );
};

interface CheckoutFormProps {
  subscriptionId: string;
  completedCallback: (invoiceId?: string, error?: string) => void;
}

const CheckoutForm = ({
  subscriptionId,
  completedCallback,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    console.log("*** SUBMITTING...", stripe, elements);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${config.siteUrl}/checkout`,
        // With appended ?payment_intent=pi_3MGxwII679iVb7iy0e7hwKQJ&payment_intent_client_secret=pi_3MGxwII679iVb7iy0e7hwKQJ_secret_jBtNfr5Z2motEFy9BpnWwqnsH&redirect_status=succeeded
      },
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      console.log("*** PAYMENT CONFIRMATION RESULT", result);

      // We'll end up here if no redirect is needed
      const { id: paymentIntentId, status: paymentStatus } =
        result.paymentIntent;

      if (paymentStatus !== "succeeded") {
        return completedCallback(undefined, `Payment failed: ${paymentStatus}`);
      }

      const {
        subscriptionType,
        status: subscriptionStatus,
        invoiceId,
      } = await apiClient.subscriptionPaymentCompleted({
        paymentIntentId,
        subscriptionId,
      });
      console.log(
        "*** API RESPONSE",
        subscriptionType,
        subscriptionStatus,
        invoiceId
      );

      if (subscriptionStatus === "active") {
        completedCallback(invoiceId);
      } else {
        completedCallback(
          undefined,
          `Payment failed: could not confirm subscription ${paymentIntentId} - ${subscriptionId}`
        );
      }

      // Your customer will be redirected to your `return_url` (if required). For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button>Submit</button>
      </form>
    </div>
  );
};
