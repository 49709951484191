import React, { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { usePlans } from "../../hooks/use-plans";
import { ApplicationContext } from "../../context/application-context";
import { apiClient } from "../../utils/api-client";

interface Props {
  invoiceId: string;
}

export const CheckoutSuccessMessage = ({ invoiceId }: Props) => {
  const { setIdentity } = useContext(ApplicationContext);

  // Refresh identity
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Will do refresh of token if required
        const identity = await apiClient.getIdentity();

        if (identity) {
          setIdentity(identity);
        }
      } catch (error) {
        console.error("Failed to refresh identity", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Payment Succesful!</h2>
      Invoice Id: {invoiceId}
    </div>
  );
};
